const login = "/login";

const editProfile = {
  fetchProfile: "/admin-user/getProfile",
  updateProfile: "/admin-user/profileUpdate",
};

const adminUser = {
  adminUrl: "/admin-user",
  getAllAdminUser: "/admin-user?nopagination",
  restoreAdmin: "/admin-user/restore",
};

const role = {
  roleUrl: "/roles",
  allRole: "/roles?nopagination",
  permissionUrl: "permission/get-permissions",
  getForumRoles: "/get-forum-roles",
};

const askExpert = {
  fetchAskExpert: "/ask-experts",
  statusUpdate: "/ask-experts/changestatus",
  fileDownload: "/ask-experts/file-download",
};

const expert = {
  expertUrl: "/experts",
  statusUpdate: "/experts/update-status",
  flagUpdate: "/experts/update-discussion-status",
  restoreExpert: "/experts/restore",
  fetchAllActiveExpert: "/experts?filter=active&nopagination",
  expertVisibleInCases: "/experts?filter=visible_in_cases&nopagination",
};

const podcast = {
  podcastUrl: "/podcasts",
  statusUpdate: "/podcasts/update-status",
  restorePodcast: "/podcasts/restore",
};

const partner = {
  partnerUrl: "/partners",
  statusUpdate: "/partners/update-status",
  restorePartner: "/partners/restore",
  fetchAllActivePartner: "/partners?filter=active&nopagination",
};

const forum = {
  fetchAllActiveForum: "/forums?filter=active&nopagination",
  forumUrl: "/forums",
  statusUpdate: "/forums/update-status",
  restoreForum: "/forums/restore",
  viewRequestList: "/forums/forumrequest",
  approveRequest: "/forums/storerequest",
  forumContribution: "/forum-contributor?nopagination",
  forumContributorMember: "/combine-search",
};

const community = {
  fetchCommunity: "/communities",
  fetchAllActiveCommunity: "/communities?filter=active&nopagination",
  statusUpdate: "/communities/update-status",
  restoreCommunity: "/communities/restore",
};

const country = {
  countryUrl: "/countries",
  fetchAllCountry: "/countries?nopagination",
  fetchCountry: "/master/fetch_country_list",
};
const stateUrl = {
  state: "/master/fetch_state_by_country", // you have to pass the dynamic country here
  fetchState: "/master/fetch_state_by_country/India",
  fetchStateByCountry: "/master/fetch_state_by_countries",
  fetchAllStates: "/master/fetch_all_states",
};
const cityUrl = {
  city: "/master/fetch_city_by_state", // pass dynamic state here
  fetchCityByState: "/master/fetch_city_by_states",
  fetchAllCities: "/master/fetch_all_cities",
};

const subspeciality = {
  fetchSubSpeciality: "/sub-specialities",
  fetchAllSubSpeciality: "/sub-specialities?nopagination",
};

const page = {
  pageUrl: "/pages",
};

const cityState = {
  fetchCityState: "/city_states",
  fetchAllActiveCityState: "/city_states?filter=active&nopagination",
};

const config = {
  configUrl: "/configs",
};

const speciality = {
  specialityUrl: "/specialities",
  statusUpdate: "/specialities/update-status",
  fetchAllSpeciality: "/specialities?nopagination",
  fetchAllActiveSpeciality: "/specialities?filter=active&nopagination",
};

const article = {
  articleUrl: "/articles",
  statusUpdate: "/articles/status",
  importFile: "/articles/import",
};

const cases = {
  caseUrl: "/cases",
  statusUpdate: "/cases/status",
  caseCommentStatusUpdate: "/cases/comment-status",
  deleteCaseMCQ: "/cases/delete-case-question",
  fetchCase: "/cases?nopagination",
  fetchAllActiveCase: "/cases?filter=active&nopagination",
};

const caseItem = {
  deleteCaseItem: "/case-item-delete",
};

const slider = {
  sliderUrl: "/sliders",
  statusUpdate: "/sliders/status",
};

const notification = {
  fetchEmail: "/email-templates?type=email",
  emailStatusUpdate: "/email-templates/status",
  emailUrl: "/email-templates",
  restoreEmail: "/email-templates/restore",

  fetchSMS: "/sms-templates?type=sms",
  smsStatusUpdate: "/sms-templates/status",
  smsUrl: "/sms-templates",
  restoreSMS: "/sms-templates/restore",

  fetchPage: "/page-notification-templates?type=page",
  pageStatusUpdate: "/page-notification-templates/status",
  pageUrl: "/page-notification-templates",
  restorePage: "/page-notification-templates/restore",

  fetchPush: "/push-notification-templates?type=push",
  pushStatusUpdate: "/push-notification-templates/status",
  pushUrl: "/push-notification-templates",
  restorePush: "/push-notification-templates/restore",
};

const series = {
  fetchSeries: "/series?nopagination",
  statusUpdate: "/series/status",
  seriesUrl: "/series",
  restoreSeries: "/series/restore",
  fetchAllSeries: "/series?nopagination",
  fetchAllActiveSeries: "/series?filter=active&nopagination",
};

const knowledgeCategory = {
  fetchKnowledgeCategory: "/knowledge-categories?nopagination",
  fetchAllActiveKnowledgeCategory:
    "/knowledge-categories?filter=active&nopagination",
};

const notification_master = {
  statusUpdate: "/notification-masters/status",
  notificationMasterUrl: "/notification-masters",
  restoreNotificationMaster: "/notification-masters/restore",
};

const userType = {
  fetchUser: "/master/fetch_user_types",
};

const whatsapp = {
  fetchWhatsapp: "/notification?notification_type=whatsapp",
  whatsappUrl: "/notification",
};

const imageBank = {
  getImage: "/image-banks?nopagination",
};

const appNotification = {
  appNotificationUrl: "/notification",
  checkApp: "/notification/check-content",
  getWhatsapp: "/whatsapp-templates?nopagination",
  getWhatsappTemplate: "/whatsapp-report?engagement_id=",
};

const dataFilters = {
  fetchDataFilters: "/datafilters",
  fetchAllDataFilter: "/datafilters?nopagination",
  dataFiltersUrl: "/datafilters",
  updateStatus: "/datafilters/update-status",
  restoreDataFilter: "/datafilters/restore",
  getFilterContent: "/datafilters/get-content",
  members: "/master/fetch_datafilter_member_types",
};

const video = {
  videoUrl: "/videos",
  restoreVideo: "/videos/restore",
  statusUpdate: "/videos/update-status",
  fetchAllVideo: "/videos?nopagination",
  fetchAllActiveVideo: "/videos?filter=active&nopagination",
};

const questionBankUrl = {
  questionBank: "/question-banks",
  fetchAllQuestion: "/question-banks?nopagination",
  importFile: "/question-banks/import",
};

const masterUrl = {
  getQuestionBankTypes: "/master/fetch-question-bank-types",
  getAttachmentType: "/master/fetch-attachment-types",
  getAttachment: "/master/fetch-attachments?nopagination",
  getUserType: "/master/fetch_user_types",
  getChannelList: "/master/get-channel-list",
  getWhatsappConfigNumbers: "/master/get-whatsapp-config-numbers",
};
const newsletter = {
  newsletterUrl: "/newsletter",
  statusUpdate: "/newsletter/status",
  fetchActiveNewsletter: "/newsletter?filter=active&nopagination",
};

const cme = {
  cmeUrl: "/cme",
  statusUpdate: "/cme/status",
};

const certificate = {
  certificateUrl: "/certificates",
  fetchAllCertificate: "/certificates?nopagination",
  fetchActiveCertificate: "/certificates?filter=active&nopagination",
  editCertificates: "admin-side-certificate",
  regenerateCertificate: "regenerateCertificates",
};

const registration = {
  registrationUrl: "/registration-templates",
  fetchAllRegistration: "/registration-templates?nopagination",
};

const live_event = {
  fetchAllLive_event: "/master/fetch-attachments?type=live_event",
  fetchLiveEvent: "/master/fetch-attachments?type=live_event&nopagination",
  liveEventUrl: "/live-event",
  updateLiveEventStatus: "/live-event/status",
  generateCertificate: "/live-event/generate-certificate",
  download: "/live-event/live-event-download",
  sendEmail: "/live-event/send-mail",
  getCertificates: "get-certificate",
  regenerateCertificate: "/live-event/regenerateCertificates",
  // Protected live events
  isProtected: "/protected-live-event",
  isProtectedMembers: "/protected-live-event/members",
  isProtectedMembersStatus: "/protected-live-event/members/status",
  isProtectedMembersExcel: "/protected-live-event/members/upload",
};

const reading_material = {
  fetchAllActiveReadingMaterial:
    "/master/fetch-attachments?type=reading_material&nopagination=1",
};

const universal_member_upload = {
  fetchUniversalMember: "/universal/members",
};

const protected_content = {
  fetchProtectedContent: "",
};

const immediate_action = {
  fetchImmediateAction: "/immediate-action",
};

const mci_verification = {
  fetchMciVerification: "/mci",
  fetchUpdateMciState: "/mci/updateMciState",
  mciUpdate: "/mci/mcistatus",
  mciUpload: "/mci/memberDocumentUpload",
  mciEmailSubmit: "/mci/editSendMailVerification",
  mciMobileSubmit: "/mci/editMobileNumber",
  mciNoSubmit: "/mci/updateMciNumber",
  doctorAssign: "/mci/docAssignAfterVerification",
  mciYearSubmit: "/mci/updateMciYear",
  mciNameSubmit: "/mci/updateUserName",
  referredBySubmit: "/mci/addMciReferrel",
  updateQualification: "/mci/updateDoctorQualification",
  fetchNotes: "/mci/getmciNotes/",
  storeNotes: "/mci/mciNotes",
  updateLeadProfileStatus: "/mci/update-lead-profile-approve-status",
  fetchInstitutes: "/mci/getInstitues",
  fetchCourses: "/mci/getCourse/",
  fetchQualification: "/mci/get-doctor-academic/",
  getStateCouncil: "mci/state-council",
};

const metatag = {
  fetchMetaTag: "/metatag",
};

const digiMr = {
  addDigimr: "/digimr/create",
  fetchDigimr: "/digimr/callers",
  fetchSingleDigimr: "/digimr/caller",
  updateDigimr: "/digimr/edit-caller",
  deleteDigimr: "/digimr/delete-caller",
};
const quiz = {
  fetchQuiz: "/live-event/get-quiz",
};

const promotion = {
  promotionUrl: "/promotions",
  updateStatus: "/promotions/update-status",
  restorePromotion: "/promotions/restore",
};

const webview = {
  webviewUrl: "/webviews",
  updateStatus: "/webviews/update-status",
  restoreWebview: "/webviews/restore",
};

const forum_tool = {
  forumToolUrl: "/forum-tools",
  forumToolVendors: "/forum-vendors",
  forumToolLicenses: "/forum-licenses",
  fetchVendorList: "/vendor-list",
  fetchLicenseList: "/license-list",
  restoreVendor: "/forum-vendors/restore",
  restoreLicense: "/forum-licenses/restore",
  updateChannels: "/forum-tools/update-channel",
  fetchFieldRepChannel: "/master/get-field-rep-channel-list",
  getFieldRepsByBrand: "/forum-tools/get-field-reps",
};

const campaign = {
  campaignUrl: "/campaigns",
  restoreCampaign: "/campaigns/restore",
  fetchSingleTouchpoint: "/fetch-touchpoints",
  analytics: "/analytics",
};

const stage = {
  stageUrl: "/stages",
  updateStageStatus: "/stages/update-status",
  restoreStage: "/stages/restore",
};

const brand_stage = {
  brandStageUrl: "/brand-review-stages",
};

const product_type = {
  getProductType: "/master/get-product-list",
};

const digimr_project = {
  fetchDigimrProjectUrl: "/digimr/project",
};

const member_tag = {
  // getMemberList: "",
  getMemberTagData: "/member-tag-types",
  updateStatus: "/member-tag-types/update-status",
  restoreData: "/member-tag-types/restore",
};

const members = {
  getMembers: "/members",
  updateMember: "/members/update-tag-type",
  getworkplace: "/members/get-work-places",
  getDoctorAcademics: "/members/get-doctor-academics",
  getPublication: "/members/get-publication",
  getQualification: "/members/get-qualifications",
  getInstitute: "/members/get-institutes",
  getLink: "/members/get-links",
  updateWorkplace: "/members/update-doctor-work-place",
  updateAcademics: "/members/update-doctor-academic",
  updatePublications: "/members/update-publication",
  updateLinks: "/members/update-link",
  deleteLinks: "/members/delete-link",
  checkPassword: "members/check-password",
  getworkplaceList: "members/get-work-places-list",
  statusUpdate: "/members/interna-user-status",
  fetchNotes: "/members/getMemberNotes/",
  storeNotes: "/members/memberNotes",
  gpMembers: "/gp-members",
  getVerication: "/members/get-member-verification",
  memebrVerification: "/members/save-member-verification",
  generateShortLink: "/members/generate_short_link",
  getSocialLinks: "/members/get-social-links",
  addSocialLinks: "/members/add-social-links",
  getMemberSocialLinks: "/members/get_member_social_links",
  getAwardsAchievements: "/awards-achievements",
  updateAwardStatus: "/awards-achievements/update-status",
  updateUserStatus: "/members/ban-user",
  deleteWorkPlace: "/members/delete-doctor-work-place",
  deleteAcademics: "/members/delete-doctor-academic",
  deletePublication: "/members/delete-publication",
  updateWorkplaceStatus: "/members/update-doctor-work-place-status",
  updateAcademicsStatus: "/members/update-doctor-academic-status",
  updatePublicationStatus: "/members/update-publication-status",
};

const adminAudjtLogs = {
  fetchAll: "/admin-audit-logs",
};
const detailOne = {
  getDetailOne: "/detail-one",
  restoreSingleDetailOne: "/detail-one/restore",
};

export const repost = "/repost";

const universal = {
  fetchUniversal: "/universal-members",
  fetchNotes: "/universal-members/getUniversalNotes/",
  storeNotes: "/universal-members/universalNotes",
  getQualification: "/universal-members/getCourse",
  updateUniversalAcademics:
    "/universal-members/updateUniversalDoctorQualification",
  getUniversalDoctorAcademics:
    "/universal-members/get-universal-doctor-academic",
  getUniversalDoctorWorkplace: "/universal-members/get-universal-work-places",
  updateUniversalWorkplace:
    "/universal-members/update-universal-doctor-work-place",
};

const post = {
  getAllPosts: "/posts",
  deletePostImageVideo: "/posts/delete-image",
  statusUpdate: "/posts/status",
  getURL: "/posts/url-data",
};

const email = {
  getEmailContent: "email-template",
};

const feedback = {
  getFeedback: "/feedbacks",
  updateFeedbackStatus: "/feedbacks/update-status",
};

const comments = {
  getComments: "/comments",
  updateCommentStatus: "/comments/update-spam-status",
};

const unsubscribe = {
  getUnsubscribe: "/unsubscribe",
  updateUnsubscribeStatus: "/unsubscribe/update-status",
};

const points = {
  getPoints: "/points",
  redeemPoints: "/points/redeem-points",
  getPointHistory: "/points/history",
};

const indonesia_payment = {
  getPaymentList: "/payments",
  confirmPayment: "/orders/confirm_payment",
  getOrderList: "/orders",
  getOrderItem: "/orders/items",
  manualPayment: "/orders/manual_payment",
  getPaymentType: "/paymentGatewayTypes",
  updateStatusPaymentType: "/paymentGatewayTypes/update-status",
  getpaymentGateways: "/paymentGateways",
  updateStatusGetways: "/paymentGateways/update-status",
  getwaytypes: "/paymentGateways/gatewaytypes",
  getPaymentlogs: "/payment-logs",
  getWallet: "/wallets",
  getWalletTransaction: "/wallet_transactions",
  getplans: "/plans",
  updatePathStatus: "/plans/update-status",
  getPlanProduct: "/plans/products",
  getCoupon: "/coupons",
  updateCouponStatus: "/coupons/update-status",
  getCouponItem: "/coupons/get-coupon-items",
  getCouponProducts: "/getproducts",
  getSubscription: "/subscription",
  getSubscriptionHistory: "/subscription-history",
  updateWalletStatus: "/wallets/update-status",
};

const product = {
  getProduct: "/products",
  updateProductStatus: "/products/update-status",
};

const tax = {
  getTax: "/taxes",
  updateTaxStatus: "/taxes/update-status",
};

const rating = {
  getRating: "/rating",
  updateRatingStatus: "/rating/update-status",
};

const faq = {
  getFaq: "/lead-faq",
  updateFaqStatus: "/lead-faq/update-status",
  getOneFaq: "/lead-faq/get-faq",
};

export {
  webview,
  promotion,
  quiz,
  askExpert,
  expert,
  podcast,
  partner,
  community,
  country,
  page,
  subspeciality,
  cityState,
  config,
  speciality,
  login,
  article,
  adminUser,
  role,
  cases,
  slider,
  forum,
  notification,
  series,
  knowledgeCategory,
  notification_master,
  userType,
  whatsapp,
  appNotification,
  dataFilters,
  video,
  stateUrl,
  cityUrl,
  masterUrl,
  questionBankUrl,
  imageBank,
  newsletter,
  certificate,
  cme,
  registration,
  live_event,
  reading_material,
  universal_member_upload,
  protected_content,
  immediate_action,
  mci_verification,
  digiMr,
  caseItem,
  editProfile,
  metatag,
  forum_tool,
  campaign,
  stage,
  brand_stage,
  product_type,
  digimr_project,
  member_tag,
  members,
  adminAudjtLogs,
  universal,
  detailOne,
  post,
  email,
  feedback,
  comments,
  unsubscribe,
  indonesia_payment,
  points,
  product,
  tax,
  rating,
  faq,
};
